#IdpdfCertificated {
  display: none;
}

.text-size-export {
  font-size: 0.8em !important;
}

.next-page-others {
  margin-top: 5%;
}

.next-page {
  margin-top: 8%;
}

.next-page-supplies {
  margin-top: 7%;
}
.next-page-supplies-2 {
  margin-top: 20%;
}

.final {
  margin-top: 37%;
  bottom: 0;
}
