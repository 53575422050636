@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

#tituloTotal {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #41495a;
  width: 100%;
  margin-right: 30px;
}
#numeros {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
}
#letras {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
}
#tituloEstadistica {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #41495a;
  margin-top: 15px;
  margin-left: 18px;
}
#tituloChildren {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #41495a;
}

#contenedorinput {
  margin-top: 35px;
}
#tituloQuantity {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #606060;
}
#tituloTime {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #bac4cd;
}
#tituloPorcentaje {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #2c2c2c;
  margin-top: 10px;
}
#estadisticaleft {
  height: 50% !important;
  width: 45%;
}
#ultimate {
  height: 50% !important;
  width: 100%;
}

.contenedor{
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  border: none;
  padding: 0;
  -webkit-transform-origin: top center; /* Restablecer transform origin */
  transform-origin: top center;
}
