#seleccionar {
  border-bottom: 2px solid #1f7ac3;
  padding: 0.3rem;
  line-height: 1.3;
}
#seleccionar:hover {
  background-color: #1f7ac3 !important ;
  color: rgb(0, 0, 0);
}

#cuzto {
  color: #fff;
}
#logocus {
  background-color: #3f89c4;
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.281);
}
#tituloaside {
  color: #fff;
  font-size: 12px;
  margin-bottom: 3vh;
  padding-left: 10px;
}
#divisor {
  background: #1f7ac3 !important ;
  padding: 2px;
}
#cuadr {
  display: flex;
  justify-content: center;
  align-items: center;
}
#alinear {
  align-items: center;
}

.alinear {
  text-align: center;
  justify-content: center;
  width: 97px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(215, 232, 254, 0.387) !important;
}

.css-xxkadm[aria-invalid="true"],
.css-xxkadm[data-invalid] {
  border-color: #3f89c4 !important;
}
