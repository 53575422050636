.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.table-header-cell {
  text-align: right;
}
.table-cell {
  text-align: left;
}

.table-cell,
.table-header-cell {
  border: 1px solid #ddd;
  padding: 3px;
}

.table-header-cell > p,
.table-cell > p {
  font-size: 12px;
}

.table-header-cell {
  background-color: #f1f1f1;
}
