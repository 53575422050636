.Business-grid {
  display: grid;
  padding: 0;
  margin: 0;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0px;
  height: 400px;
  width: 100%;
}

.Business-grid input[type="text"] {
  width: 90%;
  height: 70%;
  /*border: 1px solid black;*/
  background-color: rgb(228, 228, 228);
  border-radius: 10px;
  outline: none;
}

#Business-grid-0 {
  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 2;
  grid-column-end: 3;
}
#Business-grid-1 {
  grid-row-start: 3;
  grid-column-start: 1;

  grid-row-end: 4;
  grid-column-end: 3;
}
#Business-grid-2 {
  grid-row-start: 2;
  grid-column-start: 3;

  grid-row-end: 3;
  grid-column-end: 5;
}
#Business-grid-3 {
  grid-row-start: 4;
  grid-column-start: 1;

  grid-row-end: 5;
  grid-column-end: 3;
}
#Business-grid-4 {
  grid-row-start: 4;
  grid-column-start: 3;

  grid-row-end: 5;
  grid-column-end: 5;
}
#Business-grid-5 {
  grid-row-start: 5;
  grid-column-start: 1;

  grid-row-end: 6;
  grid-column-end: 3;
}
#Business-grid-6 {
  grid-row-start: 5;
  grid-column-start: 3;

  grid-row-end: 6;
  grid-column-end: 5;
}
#Business-grid-7 {
  grid-row-start: 6;
  grid-column-start: 1;

  grid-row-end: 7;
  grid-column-end: 3;
}
#Business-grid-8 {
  grid-row-start: 7;
  grid-column-start: 1;

  grid-row-end: 8;
  grid-column-end: 3;
}
#Business-grid-9 {
  grid-row-start: 7;
  grid-column-start: 3;

  grid-row-end: 8;
  grid-column-end: 5;
}
#Business-grid-10 {
  grid-row-start: 6;
  grid-column-start: 3;

  grid-row-end: 7;
  grid-column-end: 5;
}
#Business-grid-11 {
  grid-row-start: 8;
  grid-column-start: 1;

  grid-row-end: 9;
  grid-column-end: 3;
}
#Business-grid-12 {
  grid-row-start: 8;
  grid-column-start: 3;

  grid-row-end: 9;
  grid-column-end: 5;
}
#Business-grid-13 {
  grid-row-start: 1;
  grid-column-start: 3;

  grid-row-end: 2;
  grid-column-end: 5;
}
#Business-grid-14 {
  grid-row-start: 2;
  grid-column-start: 1;

  grid-row-end: 3;
  grid-column-end: 3;
}
#Business-grid-15 {
  grid-row-start: 3;
  grid-column-start: 3;

  grid-row-end: 4;
  grid-column-end: 5;
}
