.flexing {
  display: flex;
}
.flexingi {
  display: flex;
  padding-right: 60px;
}

.flexing-2 {
  display: flex;
  padding-right: 120px;
}
.margin {
  margin-left: 50px;
}

.spacing {
  padding-right: 300px;
}

#display {
  display: none;
}
#display-img {
  display: none;
}
.horizontal-snap {
  margin: 0 auto;
  /* display: grid;
  grid-auto-flow: column; */
  gap: 1rem;
  /* height: calc(180px + 1rem); */
  padding: 1rem;
  width: 95%;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  /* border: 10px solid black; */
}

.horizontal-snap > a {
  scroll-snap-align: center;
}

.horizontal-snap img {
  width: 150px;
  border-radius: 5px;
  max-width: none;
  object-fit: contain;
}
#marginIcon {
  /* margin: 1%; */
  padding: 3%;
  background: #cfcfcf5d;
}
#titleSection {
  font-size: 1.2rem;
  font-weight: 700;
}
#titlePhoto {
  margin-top: 5%;
  margin-left: 15%;
  font-size: 1rem;
  font-weight: 550;
}
#enlaceDow {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
#enlaceDow:hover {
  color: rgb(36, 69, 214);
  text-decoration: underline rgb(36, 69, 214); /* Subrayado rojo */
}
.hover-img {
  /* background-color: #000; */
  color: #fff;
  display: inline-block;
  margin: 8px;
  /* max-width: 320px; */
  /* min-width: 240px; */
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 150px;
}

.hover-img * {
  box-sizing: border-box;
  transition: all 0.45s ease;
}

.hover-img:before,
.hover-img:after {
  background-color: rgba(0, 0, 0, 0.308);
  border-top: 32px solid rgba(0, 0, 0, 0.308);
  border-bottom: 32px solid rgba(0, 0, 0, 0.308);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  transition: all 0.3s ease;
  z-index: 1;
  opacity: 0;
  transform: scaleY(3);
}

.hover-img img {
  vertical-align: top;
  max-width: 100%;
  backface-visibility: hidden;
}

.hover-img figcaption {
  position: absolute;
  top: 0;
  /* bottom: 0;
  left: 0; */
  right: 0;
  align-items: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2em;
  opacity: 0;
  z-index: 2;
  transition-delay: 0.1s;
  font-size: 17px;
  font-family: sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.hover-img:hover:before,
.hover-img:hover:after {
  transform: scale(2);
  opacity: 1;
}

.hover-img:hover > img {
  opacity: 0.7;
}

.hover-img:hover figcaption {
  opacity: 1;
}
#display-vacio {
  display: none;
}
#search-avanzada {
  cursor: pointer;
}

#redonder {
  border-radius: 99%;
  background: #03c39a;
  width: 90px;
  height: 90px;
}
