.navbar-toggler {
  border: none !important;
  outline: none !important;
}
.text-input {
  font-size: 14px;
}
.select-stile {
  padding: 0.7em;
}
.size {
  width: 95% !important;
}

@media (max-width: 850px) {
  .resize {
    font-size: 0.8em !important;
    flex-direction: column !important;
  }
  .resize-value {
    font-size: 0.8em;
  }
  .text-frezone {
    margin-top: 2%;
    font-size: 11px !important;
  }
}
