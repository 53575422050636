.BorderCorrect {
  border: 1px solid #592487 !important;
  border-radius: 5px;
}
.BorderCorrectTabla {
  border: 3px solid #592487 !important;
  border-radius: 5px;
}
.cursorBtn {
  cursor: pointer;
}
#IdPdfFmmCorrectEntry {
  display: none;
}
#IdPdfestadisticas {
  display: none;
}
