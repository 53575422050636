.displayRespo {
  display: none;
}

.content-sidebar, .content-sidebar {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1003;
}
/* .pro-sidebar-layout::-webkit-scrollbar{
  background: transparent;
  width: 1px;
} */

/* width */
.pro-sidebar-layout::-webkit-scrollbar {
  width: 1px;
}

/* Track */
.pro-sidebar-layout::-webkit-scrollbar-track {
  box-shadow: inset 0 0 11px #c0c0c0;
  border-radius: 10px;
}

/* Handle */
.pro-sidebar-layout::-webkit-scrollbar-thumb {
  background: rgb(131, 118, 118);
  border-radius: 10px;
}

/* Box Fragmento */
.active {
  width: 100% !important;
  height: 45px !important;
  background-color: #c0c0c0;
  /* color: white; */
}

.activeItem {
  color: #1f7ac3 !important;
  background-color: #fff !important;
  border-radius: 3px !important;
  padding: 2px !important;
  animation: vibrate 0.2s 1 !important;
  scale: 1.4;
  margin: 10px 7px;

}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-3px);
  }
  20% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0);
  }
}

.notActiveItem {
  color: #fff !important;
  background-color: #c0c0c000 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

/* 
.containerFrag::-webkit-scrollbar {
  display: none;
}

.containerFrag {
  background-color: var(--zf-primary);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100vh !important;
} */

.box {
  margin-bottom: 10px;
}

.box:hover {
  color: white !important;
}

/* Box de icono y nombre */
.box-name {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

/* span {
  color: rgb(255, 242, 242) !important;
} */

/* Barra Separadora */

hr {
  width: 90%;
  height: 1px !important;
  margin-bottom: 7px !important;
  /* margin-bottom: 5%; */
}

/* Boton Formulario */

.boxform {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 190px;
  border-radius: 8px;
  background: white;
  box-shadow: 1px 2px 4px black;
}

.color-text {
  color: #1f7ac3;
  font-size: 12px;
}
.visible-scroll {
  display: block;
  width: 10em;
  overflow: inherit;
}
.fontForm {
  font-size: 13px;
}
.logoaside {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: var(--zf-primary);
}
.fontAside {
  font-size: 12px;
}
.pro-sidebar > .pro-sidebar-inner {
  background: var(--zf-primary);
  height: 100%;
  position: relative;
  z-index: 101;
}
.imgLogoAside {
  width: 232px;
  height: 100px;
  padding-bottom: 20px;
}
.imgLogoAsideTwo {
  margin-left: 6px;
  width: 50px;
  height: 40px;
}
#borderDiv {
  position: absolute !important;
  margin-top: 10px;
  margin-left: 92%;
  z-index: 3 !important;
}
#borderDivtrue {
  position: absolute !important;
  margin-top: 10px;
  margin-left: 70%;
  z-index: 3 !important;
}
