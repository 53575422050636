.direction{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: aliceblue !important;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}