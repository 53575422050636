.borderforms {
  box-sizing: border-box;
  width: 50%;
  min-height: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin: 0.5rem 0.5rem;
  padding: 0.9rem;
  border-radius: 5px;
}

.total_unit-styles {
  padding-right: 18px !important;
}

.text-supply-tablet {
  font-size: 0.8em;
}

.text-title-item {
  font-weight: 600;
}

@media (max-width: 844px) {
  .buttons {
    display: flex;
    justify-content: center !important;
    margin-left: 7% !important;
    padding-left: 40% !important;
  }
  .button-qr {
    margin-left: 7%;
    padding-left: 100% !important;
  }
  .text-title-item {
    font-size: 0.71em !important;
    margin-top: 5%;
  }
  .text-items-tabs {
    font-size: 0.6em !important;
  }

  .icons {
    width: 15px !important;
  }
}
