.formNumber {
  color: #1f7ac3;
}

.formNumber:hover {
  color: #3f8dce;
  cursor: pointer;
  text-decoration: underline #1f7ac3;
}
.stateCustoms{
  background: #dcdbdb;
  padding: 2px 8px ; 
  border-top-left-radius: 10px ;
  border-bottom-left-radius: 10px ;
  border-top-right-radius: 10px ;
  border-bottom-right-radius: 10px ;
}

#warningInsp{
  background: #ffe69c8a;
}

#BluewarningInsp{
  background: #9ccfff8a;
}