.noStyle {
  list-style: none;
}
.name-label {
  width: 100%;
}

.check-box {
  width: 100%;
}
