.z-index {
  width: 95%;
  position: absolute !important;
  z-index: 2 !important;
}

.z-index-2 {
  width: 90%;
  position: absolute !important;
  z-index: 2 !important;
}

.z-index-3 {
  /* width: 90%; */
  position: absolute !important;
  z-index: 5 !important;
}

.z-index-4 {
  width: 43%;
  position: absolute !important;
  z-index: 5 !important;
}
