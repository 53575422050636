@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto+Condensed:wght@300&display=swap");

#title {
  font-size: 15px;
  margin: 5px;
  font-weight: 500;
}

#date {
  font-size: 11px;
  font-weight: 300;
  color: #2a2f33;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
  justify-content: end;
  margin: 10px auto;
}

#cardContent {
  margin: auto 3px;
  font-size: 11px;
  font-weight: 500;
  color: #2a2f33;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
}

#cardFooter {
  margin: 5px auto;
  font-size: 11px;
  font-weight: 500;
  color: #2a2f33;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
  text-decoration-line: underline;
  cursor: pointer;
}

#headeCardPeso {
  background: #1f7ac3;
}
