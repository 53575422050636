#seleccionar {
  border-bottom: 2px solid #1f7ac3;
}
#seleccionar:hover {
  background-color: #1f7ac3 !important ;
  color: rgb(0, 0, 0);
}
#cuzto {
  color: #fff;
}
#logocus {
  background-color: #3f89c4;
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.281);
}
#tituloaside {
  color: #fff;
  font-size: 12px;
  margin-bottom: 3vh;
}
#divisor {
  background: #1f7ac3 !important ;
  padding: 2px;
}
#cuadr {
  display: flex;
  justify-content: center;
  align-items: center;
}
#customi {
  color: #f9a621;
}

.user {
  margin: 5%;
  background: #f1f1f1;
  border: 1px solid rgb(179, 173, 173);
  border-radius: 5px;
  padding: 2%;
  display: flex;
  flex-wrap: wrap;
}

.sobreNo {
  border-radius: 50px;
  height: 6px !important;
  background: #2773b1 !important;
  width: 72% !important;
  opacity: 100 !important;
}
.colorFont {
  color: #2773b1;
}

.input-alinear{
  margin-top: 6.5%;
}