#contenedorEstadistica {
  width: 100%;
  box-shadow: 0 3px 6px #00000016;
}
#contenedorResultado {
  width: 100%;
  /* height: 100vh; */
}
#contenedorVehicle {
  width: 100%;
  /* height: 100vh; */
}

#item {
  width: 90%;
  background: #f5f5f5;
}
#contenedorLista {
  width: 30%;
  box-shadow: 0 3px 6px #00000016;
}
.cursorDetail {
  cursor: pointer;
}
