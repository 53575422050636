.bold {
  font-weight: 600;
}

.verticalNone {
  vertical-align: middle;
}

.birde {
  outline: #1f7ac3 2px solid;
  border-radius: 2px;
  margin-top: 6px;
}

.items {
  outline: #1f7ac3 2px solid;
  border-radius: 2px;
}
