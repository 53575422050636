
:root {
  --zf-dark: #171A22;
  --zf-nepal: #9097BE;
  --zf-white: #ffffff;
  --zf-silver: #bababa;
  --zf-wild-sand: '#000';
  --zf-primary:  #1F7AC3;
  --zf-ardinal: #ba1f33;
  --zf-tamarillo: #91171e;
  --zf-bright-sun: #ffd449;
  --zf-venice-blue: #05668e;
  --zf-blue-lagoon: #048090;
  --zf-boston-blue: #3f89c4;
  --zf-persian-green: #03a896;
  --zf-sea-buckthorn: #f9a621;
  --zf-chathams-blue: #105185;
  --zf-aribbean-green: #03c39a;
  --zf-resolution-blue: #001E75;
}