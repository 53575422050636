/* react tabs scrollable */

.display___block {
  display: block;
}
.display____inline___block {
  display: inline-block;
}
.display___none {
  display: none !important;
}
@media (max-width: 991.98px) {
  .display___md___none {
    display: none !important;
  }
}

.rts___tabs___container {
  display: flex;
}
@media (max-width: 991.98px) {
  .rts___tabs___container {
    padding: 5px;
  }
}
.rts___tabs {
  display: flex;
  overflow: auto;
  padding: 10px 0;
}
.rts___tabs.hide___rts___tabs___scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.rts___tabs.hide___rts___tabs___scroll::-webkit-scrollbar {
  display: none;
}
.rts___tab {
  padding: 10px 40px;
  white-space: nowrap;
  vertical-align: middle;

  text-align: center;
  margin: 0 0 0 0;
}

@media (max-width: 991.98px) {
  .rts___tab {
    margin: 0 2px;
  }
}
@media (max-width: 767.98px) {
  .rts___tab {
    padding: 5px 20px;
  }
}

.rts___nav___btn___container {
  display: flex;
}

.rts___btn {
  cursor: pointer;
  /*border-radius: 40px;*/
  border-bottom: 2px solid rgb(134, 129, 129);
  transition: 0.3s all linear;
}
.rts___tab___selected {
  background-color: var(--rts-primary-color);
  border-bottom: 2px solid rgb(43, 108, 176);
  color: rgb(43, 108, 176);
  /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;*/
}

.rts___nav___btn {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rts___nav___btn:hover {
  background-color: var(--rts-primary-color);
  transition: 0.5s all;
}
.rts___nav___btn:hover > svg {
  stroke: rgb(0, 0, 0);
}
@media (max-width: 767.98px) {
  .rts___nav___btn > svg {
    width: 15px;
  }
}
button:disabled.rts___btn,
button[disabled].rts___btn {
  cursor: not-allowed;
  color: var(--rts-gray-color);
  pointer-events: none;
}
button:disabled.rts___btn,
button[disabled].rts___btn svg {
  stroke: gray;
}

.rts___right___nav___btn {
  border: none;
  color: rgba(0, 0, 0, 0.6);
}
.rts___left___nav___btn {
  border: none;
  color: rgba(0, 0, 0, 0.6);
}
