.tamano-forms {
  width: 50px !important;
}
.child {
  background-color: aquamarine;
}

.description {
  resize: none !important;
  color: black !important;
}
