.ScrollMinimal::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background-color: #F5F5F5;
  }
  
  .ScrollMinimal::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  