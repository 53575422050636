@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto+Condensed:wght@300&display=swap");

/* #scroolhiden {
  overflow: hidden;
} */
#contenedorComm {
  padding: 6%;
  height: 100%;
  width: 100%;
  background: #efefef41;
  border-radius: 4px;
}

.chakra-modal__body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.chakra-modal__body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 11px #c0c0c0;
  border-radius: 10px;
}

/* padding-right: 100px !important; */
/* Handle */
.chakra-modal__body::-webkit-scrollbar-thumb {
  background: rgb(131, 118, 118);
  border-radius: 10px;
}

#messageOtherUser {
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 5%;
  width: 75%;
  height: auto;
  padding: 2%;
  border-radius: 4px;
  background: #e9e9e9;
  font-size: 15px;
  color: #171a22;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

#messageMyUser {
  height: auto;
  width: 75%;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 20%;
  padding: 2%;
  border-radius: 4px;
  background: #6eb8f5;
  font-size: 15px;
  color: #171a22;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
#UserMessage {
  color: #171a22;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
}
#timeMessage {
  font-size: 10px;
  color: #171a22;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
  display: flex;
  justify-content: end;
  margin: 5px auto;
}

/* #contenedorComm::-webkit-scrollbar {
  display: none;
} */

/* #contenedorComm {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
