@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Roboto+Slab&display=swap");

.contenedor-trm {
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(200px, 2fr) 2fr;
}

.updateTrm {
  display: flex;
  /* width: 100%; */
  margin-left: 100px;
}

.title-info-trm {
  margin-top: 4%;
  font-family: "Roboto Slab", serif;
  margin-bottom: 3%;
}

.title-info-trm h1 {
  font-size: 1.2rem;
  color: #9097be;
}

.info-previus {
  margin-bottom: 2%;
  display: flex;
  font-family: "Roboto Slab", serif;
}

.info-previus h1 {
  font-size: 1.1em;
}

/* span {
  margin-left: 2%;
} */

.valueFormat {
  font-size: 4em;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.info-day {
  font-size: 1.2em;
  font-family: "Roboto Slab", serif;
}

@media (max-width: 768px) {
  .valueFormat {
    font-size: 2.2em;
    width: 100%;
    font-family: "Poppins", sans-serif;
  }
  .updateTrm {
    display: flex;
    width: 100%;
    margin-left: 100px;
  }
}

@media (max-width: 820px) {
  .valueFormat {
    font-size: 2.1em;
    width: 100%;
    font-family: "Poppins", sans-serif;
  }
  .updateTrm {
    flex-direction: column;
    width: 70%;
  }
}

@media (max-width: 991px) {
  .valueFormat {
    font-size: 2em;
    width: 100%;
    font-family: "Poppins", sans-serif;
  }
}
