/* .wrapper{
     display: flex;
     justify-content: center;
     flex-wrap: wrap;
} */

.containerInfo {
  height: 70vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.3rem;
  justify-content: center;
}

.btn-flotante {
  position: fixed;
  bottom: 40px;
  right: 100px;
  transition: all 300ms ease 0ms;
  z-index: 99;
}
.comer {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .btn-flotante {
    font-size: 14px;
    padding: 12px 20px;
    bottom: 20px;
    right: 20px;
  }
}
