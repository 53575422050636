#noptions {
  cursor: not-allowed;
}

.spac {
  margin-left: 30px;
}

.container-unidad-medida{
  width: 45%;
  display: flex;
  align-items: end;
}

.input-unidad{
  width: 85%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-right: 0;
  
}

.btn-unidad{
  padding: 0px;
  width: 15%;
}

.btn-unidad button{
  background-color:#1F7AC3;
  height: 38px !important;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 0;

}