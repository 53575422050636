#adventenciaReturn {
  padding: 1%;
  background: #eaec4e5d;
}

#datosFiltro {
  padding: 1%;
  background: #74a6cf;
}

.spacing {
  padding-right: 20.5%;
}
