/* .wrapper{
     display: flex;
     justify-content: center;
     flex-wrap: wrap;
} */

.name {
  font-size: 1.2rem;
  font-weight: bold;
  /* margin-bottom: 0.5rem; */
  color: var(--zf-chathams-blue);
  /* display: flex; */
  /* align-items: center; */
}

.containerItems {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 3px;
  /* margin-bottom: 0.3rem; */
  align-items: center;
}

.titleItem {
  font-size: 1rem;
  font-weight: bold;
  /* margin-bottom: 0.5rem; */
  color: var(--zf-dark);
}


.descriptionItem {
  font-size: 1rem;
  /* margin-bottom: 0.5rem; */
  color: var(--zf-dark);
}
