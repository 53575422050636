.focus {
  color: #1f7ac3 !important;
  background-color: #fff !important;
  border-radius: 3px !important;
  padding: 2px !important;
  animation: vibrate 0.2s 1 !important;
  scale: 1.4;
  margin: 10px 7px;
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-3px);
  }
  20% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0);
  }
}

.notFocus {
  color: #fff !important;
  background-color: #c0c0c000 !important;
  border-radius: 0 !important;
  padding: 0 !important;

}

.imgFocus{
  height: 15px;
  margin: 2px;
  color: #1f7ac3;
  border-bottom: 2px solid #fff;
  padding: 1px;
  animation: vibrate 0.2s 1 !important;
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-3px);
  }
  20% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0);
  }
}

.imgNotFocus{
  height: 15px;
  margin: 2px;
  color: #fff;
  border-bottom: none;
  padding: 0;
}