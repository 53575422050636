#noViewUser {
  background: #96c9f37b;
  border-radius: 5px;
}

.dateNotification {
  font-family: "Roboto";
  color: #595959;
  font-weight: 500;
  font-size: 10px;
}
.titleNotification {
  font-family: "Roboto";
  color: #171a22;
  font-weight: 400;
  font-size: 16px;
}
.titleNotif {
  font-family: "Roboto";
  color: #171a22;
  font-weight: 400;
  font-size: 16px;
}
.titleNotification:hover {
  color: var(--zf-primary);
}
.descriptionNotification {
  font-family: "Roboto";
  color: #616161;
  font-weight: 400;
  font-size: 14px;
}
.carddrap {
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: "center";
  font-size: "20px";

  /* backgroundColor: "lightblue", */
  /* padding */
}

.carddrap:hover {
  cursor: grab;
}
.active {
  width: 100% !important;
  height: 45px !important;
  background-color: transparent !important;
  color: #171a22 !important;
}
.centerIcon {
  margin-left: 35%;
  margin-top: 50%;
}
