.containerDataOfInterest {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.title{ 
  font-size: 0.9em;
  font-weight: bold;
  margin-right: 5px;
  text-align: left;
}

.descrip {
  font-size: 0.9em;
  color: var(--zf-secondary);
}

.containerItems {
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  justify-content: space-between;
}