#pdfId {
  display: none;
}
#IdPdfPesaje {
  display: none;
}

#banner {
  background: linear-gradient(to right, #1fa2ff, #12d8fa, #43d6f017);
  width: 77%;
  height: 65px;
  margin-top: 5px;
}

#footer {
  width: 100%;
  background: #000;
  height: 55px;
  /* margin-top: 20px !important;
  position: absolute;
  bottom: 0; */
}

p {
  font-size: 13px;
}

#firma {
  border-top: 2px solid rgb(148, 148, 148);
  width: 200px;
}
#espaciofail {
  height: 62.1vh;
  width: 100%;
  /* background: #000; */
}
