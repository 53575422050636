.containerAccordion {
  margin-bottom: 20px;
  border-radius: 8px;
}

.containerIcon {
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: royalblue;
}

.containerTile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.containerTile p {
  text-align: left;
  font-size: 0.8em;
}

.title {
  margin-left: 10px;
  text-align: left;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.containerIconDropDown {
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--zf-primary);
}
