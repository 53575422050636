@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

.titlezf {
  font-family: "Poppins", sans-serif;
  text-align: center;
  /* width: 371px;
  height: 73px; */
  font-size: 30px;
  color: #41495a;
  font-weight: bold;
  margin-top: 10px;
}

.checkIniciar {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
}

.colorFollow {
  color: #1e1c66;
}

.container_{
  display: flex;
  justify-content: center;
  align-items: flex-start;

}

.social-media{
  padding:2px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #1f7ac3;
  width: 25%;
  line-height: 0.9;
  font-size: 14px;
  font-weight: 400;
}