#invisible {
  display: none;
}

.margin-third {
  margin-top: 8%;
}

.color-text {
  color: #454546;
}

.margin-data {
  margin-top: 33px;
}

#downloadFile {
  cursor: pointer;
  color: #1f7ac3 !important;
}

#downloadFile:hover {
  text-decoration: underline;
}

.BorderError {
  background: #ff545479;
  /* color: #f44242; */
}
#adventenciaReturn {
  padding: 1%;
  background: #fcf8e3 !important;
}
