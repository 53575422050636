.boton {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 150;
    margin-left: 69px;
}

.datosInsumo {
    background: rgba(0, 0, 0, 0.614);
    max-width: 1200px;
    width: 80vw;
    color: white;
    padding: 10px;
    border-radius: 5px;
    bottom: 0px;
    padding-bottom: 40px;
    position: fixed;
    z-index: 100;

}

@media (max-width: 720px) {
    .boton {
        bottom: 5px;
    }

    .datosInsumo {
        padding-bottom: 50px;
        width: 85%;
        left: 95px;
    }
}