.spac {
  margin-top: 5px;
}
.margin-input {
  margin-top: 2%;
}

.contorno {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  margin-left: 33px;
}

.borde {
  margin-left: 26px;
}

.text-word {
  font-size: 1.1em;
  font-weight: 600;
}

.content-word-text {
  margin-bottom: 5px;
  margin-left: 25px;
}

.Text-font {
  font-weight: 600;
}

.aling-text {
  padding: 2px;
}

#contenedores {
  padding: 1%;
  background: #cfcfcf5d;
}

.text-status {
  font-weight: 600;
}
#listDocument {
  list-style: none;
}

.margin-search {
  margin-top: 3%;
  margin-right: 27% !important;
}

#IdPdfFmmEntry {
  display: none;
}
#IdPdfFmmOutput {
  display: none;
}
.textCorrecte {
  font-weight: 700;
}
