.boxs {
  width: 100% !important;
  display: flex !important;
}

.spacingin {
  width: 250px !important;
  margin-left: 73px !important;
}

.spacingini {
  width: 210px !important;
}

/* .buttonConfirm-class{
  border-radius: 2px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60%;
  color: white;
  background-color: #1F7AC3;
}
.buttonCancel-class {
  border-radius: 2px;
  display: flex;
  width: 100%;
  color: white;
  margin-top: 4%;
  height: 60%;
  background-color: #d33;
}
.cont-class{
  padding: 10px;
} */
.class-text{
  font-size: 1.2rem !important;
}